import { NsfwSpy } from "@nsfwspy/browser";
import "./model.json";
import "./group1-shard1of6.bin";
import "./group1-shard2of6.bin";
import "./group1-shard3of6.bin";
import "./group1-shard4of6.bin";
import "./group1-shard5of6.bin";
import "./group1-shard6of6.bin";
import NsfwResult from "./types/NsfwResult";
import NsfwWindow from "./types/NsfwWindow";

// @ts-ignore
const url = `${__webpack_public_path__}model.json`;
const nsfWindow: NsfwWindow = window;

nsfWindow.scanVideoForNsfw = scanVideoForNsfw;
nsfWindow.scanImageForNsfw = scanImageForNsfw;
let nsfwSpy = new NsfwSpy(nsfWindow.modelPath ?? url);
const canvas = document.createElement("canvas");
canvas.style.display = "none";
document.body.appendChild(canvas);
const context = canvas.getContext("2d", { willReadFrequently: true });

console.log("context", context);

async function init() {
  console.log("init fired");
  nsfwSpy = new NsfwSpy(nsfWindow.modelPath ?? url);
//   nsfwSpy = new NsfwSpy("http://localhost:8080/model.json");
  await nsfwSpy.load();
//   const fileInput: HTMLInputElement | null = document.getElementById(
//     "file-input"
//   ) as HTMLInputElement;
//   console.log("fileInput", fileInput);
//   fileInput?.addEventListener("change", () => {
//     console.log("event change fired");
//     if (fileInput?.files?.length) {
//       const url = URL.createObjectURL(fileInput.files[0] as File);
//       scanVideoForNsfw(url).then(res => console.log("result", res));
//     // scanImageForNsfw(url);
//     }
//   });
}

export async function setMediaUrl(numbers: string) {
  const buffer: ArrayBuffer = new Int8Array(
    numbers.split(",").map((byte) => parseInt(byte, 10))
  ).buffer;
  const url = URL.createObjectURL(new Blob([buffer]));
  /** @ts-ignore */
  // Tester.postMessage(`Here is the media Url ${url}`);
  nsfWindow.mediaUrl = url;
}

export async function scanImageForNsfw(
  url: string
): Promise<NsfwResult | null> {
  return new Promise((res) => {
    const img = document.createElement("img");
    img.style.display = "none";
    img.src = url;
    img.crossOrigin = "Anonymous";
    document.body.appendChild(img);
    img.onload = async () => {
      const width = img.naturalWidth;
      const height = img.naturalHeight;
      canvas.width = width;
      canvas.height = height;
      context?.clearRect(0, 0, width, height);
      context?.drawImage(img, 0, 0, width, height);
      const imageData = context?.getImageData(0, 0, width, height);
      if (imageData) {
        const result = await nsfwSpy.classifyImage(imageData);

        const isNsfw = (result.pornography ?? 0) > .77 && result.isNsfw && result.predictedLabel === 'pornography';
        res({
          ...result,
          isNsfw,
          ...(isNsfw && { indecentImageUrl: canvas?.toDataURL() }),
        });
      } else {
        console.log("result of image scan is null");
        res(null);
      }
    };
  });
}

export async function scanVideoForNsfw(url: string): Promise<NsfwResult> {
  return new Promise((res, rej) => {
    const video = document.createElement("video");
    const body = document.querySelector("body");
    video.style.display = "none";
    video.src = url;

    video.addEventListener("loadedmetadata", async () => {
      video.play();
      video.pause();
      /** @ts-ignore */
      // Tester.postMessage(`Loaded metadata`);
      /** @ts-ignore */
      // Tester.postMessage(`Duration of video ${video.duration}`);
      const timePoints = getTimePoints(video);
      const result = await lookForNsfw(timePoints, video);
      body?.removeChild(video);
      console.log("result", result);
      res(result);
    });
    video.crossOrigin = "Anonymous";
    video.addEventListener("error", (event) => {
      console.log("video url", url);
      console.log("Error loading video", (event.target as any).error.message);
      rej((event.target as any).error.message);
    });
    console.log("error listner set");
    body?.append(video);
  });
}

function getTimePoints(video: HTMLVideoElement): number[] {
  const totalDuration = video.duration;
  const breakPoints = 30;

  // Calculate the time interval to break the video into 10 equal parts
  const interval = totalDuration / breakPoints;

  // Array to store equal time points
  const timePoints: number[] = [];

  // Calculate the time points and store them in the array
  for (let i = 0; i < breakPoints; i++) {
    const timePoint = i * interval;
    timePoints.push(timePoint);
  }

  // Log the time points to the console
  console.log("Time Points:", timePoints);
  return timePoints;
}

async function lookForNsfw(
  timePoints: number[],
  video: HTMLVideoElement
): Promise<NsfwResult> {
  let finalResult: NsfwResult = {
    isNsfw: false,
  };
  let i = 0;
  if (canvas) {
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    context?.clearRect(0, 0, video.videoWidth, video.videoHeight);
  }
  while (!finalResult.isNsfw && i < timePoints.length - 1) {
    const time = timePoints[i];
    // video.currentTime = time ?? 0;
    console.log("video current time", video.currentTime);
    console.log("time", time);
    // Wait for the video to seek to the desired time (optional but recommended)
    await new Promise((res, rej) => {
      video.onseeked = async function () {
        // Draw the current frame onto the canvas
        const width = video.videoWidth ?? canvas?.width ?? 150;
        const height = video.videoHeight ?? canvas?.height ?? 150;
        context?.drawImage(video, 0, 0, width, height);

        // Get the snapshot as a data URL
        const imageData = context?.getImageData(0, 0, width, height);

        if (imageData) {
          // console.log("image data exists", imageData);
          try {
            const result = await nsfwSpy.classifyImage(imageData);
            console.log(result.isNsfw);
            if (result.isNsfw && result.pornography > .77 && result.predictedLabel === 'pornography') {
                console.log('tagged result', result);
              finalResult.isNsfw = true;
              context?.putImageData(imageData, 0, 0);
              const dataURL = canvas?.toDataURL();
              const buffer: number[] | null = await new Promise((res, rej) => {
                canvas?.toBlob(async blob => {
                  const buffer = await blob?.arrayBuffer();
                  if (!buffer){
                    res(null);
                    return;
                  }
                  const uint8 = new Uint8Array(buffer);
                
                  res(Array.from(uint8));
                })
              });
   
              finalResult = {
                ...result,
                isNsfw: (result.pornography ?? 0) > 0.8 && result.isNsfw,
                indecentImageUrl: dataURL,
                buffer,
              };
              //    const div = document.createElement("div");
              //    const img = document.createElement("img");
              //    img.src = finalResult.indecentImagUrl as string;
              //    img.height = video.videoHeight;
              //    img.width = video.videoWidth;
              //    div.appendChild(img);
              //    const p = document.createElement("p");
              //    let pTextContent = "";
              //    const finalResultCopy = {...finalResult};
              //    console.log("finalResultCopy", finalResultCopy);
              //    Object.keys(finalResultCopy).forEach(key => {
              //     pTextContent += `${key}: ${
              //       finalResultCopy[key as keyof NsfwResult]
              //     }\n`;
              //    });
              //    p.textContent = pTextContent;
              //    p.style.fontSize = '14px';
              //    div.appendChild(p);
              //    document.body.append(div);
            }
          } catch (e) {
            console.log("Error calling image classifier", e);
            console.log(e);
            console.log(JSON.stringify(e));
            rej(e);
          }
        }
        res(true);
      };
      video.currentTime = time ?? 0;
    });
    i++;
  }
  console.log("finalResult", finalResult.isNsfw);
  return finalResult;
}

init();
